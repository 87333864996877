import React from 'react';
import { getLangFile } from 'utils/lang';
import AnimatedLink from 'components/AnimatedLink/AnimatedLink';
import './ContactUs.scss';

const ContactUs = () => {
  const t = getLangFile().contactUs;

  return (
    <div className="ContactUs">
      <h1>{t.title}</h1>
      <div className="ContactUs-line">
        <div>{t.text}</div> <AnimatedLink text={t.getInTouch} link="/contact" />
      </div>
    </div>
  );
};

export default ContactUs;
